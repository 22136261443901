import OrderSourceTypeCode from "./orderSourceTypeCode";

interface IOrderSourceMetaData {
  displayName: string;
  typeCode: OrderSourceTypeCode;
  code: string;
  icon: string;
  image?: string;
  supportsImportInHub?: boolean;
  supportsAddInHub?: boolean;
}

const oldIconBasePath = "/images/store-icons/";

const imageBasePath = "/images/order-sources/";
const newIconBasePath = `${imageBasePath}icons/`;

const orderSources: Record<OrderSourceTypeCode, IOrderSourceMetaData> = {
  [OrderSourceTypeCode.Unknown]: {
    displayName: "Unknown",
    typeCode: OrderSourceTypeCode.Unknown,
    code: "UNKNOWN",
    icon: "",
  },
  [OrderSourceTypeCode.Miva]: {
    displayName: "Miva Merchant",
    typeCode: OrderSourceTypeCode.Miva,
    code: "MIVA",
    icon: `${oldIconBasePath}miva.png`,
  },
  [OrderSourceTypeCode.Ebay]: {
    displayName: "eBay",
    typeCode: OrderSourceTypeCode.Ebay,
    code: "EBAY",
    icon: `${oldIconBasePath}ebay.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.Yahoo]: {
    displayName: "Yahoo!",
    typeCode: OrderSourceTypeCode.Yahoo,
    code: "YAHOO",
    icon: `${oldIconBasePath}yahoo.png`,
  },
  [OrderSourceTypeCode.ShopSite]: {
    displayName: "ShopSite",
    typeCode: OrderSourceTypeCode.ShopSite,
    code: "SHOPSITE",
    icon: `${oldIconBasePath}shopsite.png`,
  },
  [OrderSourceTypeCode.MarketplaceAdvisor]: {
    typeCode: OrderSourceTypeCode.MarketplaceAdvisor,
    code: "MARKETWORKS",
    displayName: "MarketplaceAdvisor",
    icon: `${oldIconBasePath}channeladvisor.png`,
  },
  [OrderSourceTypeCode.osCommerce]: {
    typeCode: OrderSourceTypeCode.osCommerce,
    code: "OSCOMMERCE",
    displayName: "osCommerce",
    icon: `${oldIconBasePath}oscommerce.png`,
  },
  [OrderSourceTypeCode.ProStores]: {
    typeCode: OrderSourceTypeCode.ProStores,
    code: "PROSTORES",
    displayName: "ProStores",
    icon: `${oldIconBasePath}prostores.png`,
  },
  [OrderSourceTypeCode.ChannelAdvisor]: {
    typeCode: OrderSourceTypeCode.ChannelAdvisor,
    code: "CHANNELADVISOR",
    displayName: "ChannelAdvisor",
    icon: `${oldIconBasePath}channeladvisor.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.Infopia]: {
    typeCode: OrderSourceTypeCode.Infopia,
    code: "INFOPIA",
    displayName: "Infopia",
    icon: `${oldIconBasePath}infopia.png`,
  },
  [OrderSourceTypeCode.CreLoaded]: {
    typeCode: OrderSourceTypeCode.CreLoaded,
    code: "CRELOADED",
    displayName: "CRE Loaded",
    icon: `${oldIconBasePath}creloaded.png`,
  },
  [OrderSourceTypeCode.Amazon]: {
    typeCode: OrderSourceTypeCode.Amazon,
    code: "AMAZON",
    displayName: "Amazon",
    icon: `${oldIconBasePath}amazon.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.XCart]: {
    typeCode: OrderSourceTypeCode.XCart,
    code: "XCART",
    displayName: "X-Cart",
    icon: `${oldIconBasePath}xcart.png`,
  },
  [OrderSourceTypeCode.OrderMotion]: {
    typeCode: OrderSourceTypeCode.OrderMotion,
    code: "ORDERMOTION",
    displayName: "OrderMotion",
    icon: `${oldIconBasePath}ordermotion.png`,
  },
  [OrderSourceTypeCode.ZenCart]: {
    typeCode: OrderSourceTypeCode.ZenCart,
    code: "ZENCART",
    displayName: "Zen Cart",
    icon: `${oldIconBasePath}zencart.png`,
  },
  [OrderSourceTypeCode.VirtueMart]: {
    typeCode: OrderSourceTypeCode.VirtueMart,
    code: "VIRTUEMART",
    displayName: "VirtueMart",
    icon: `${oldIconBasePath}virtuemart.png`,
  },
  [OrderSourceTypeCode.ClickCartPro]: {
    typeCode: OrderSourceTypeCode.ClickCartPro,
    code: "CLICKCARTPRO",
    displayName: "ClickCartPro",
    icon: `${oldIconBasePath}clickcartpro.png`,
  },
  [OrderSourceTypeCode.PayPal]: {
    typeCode: OrderSourceTypeCode.PayPal,
    code: "PAYPAL",
    displayName: "PayPal",
    icon: `${oldIconBasePath}paypal.png`,
  },
  [OrderSourceTypeCode.Volusion]: {
    typeCode: OrderSourceTypeCode.Volusion,
    code: "VOLUSION",
    displayName: "Volusion",
    icon: `${oldIconBasePath}volusion.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.NetworkSolutions]: {
    typeCode: OrderSourceTypeCode.NetworkSolutions,
    code: "NETWORKSOLUTIONS",
    displayName: "Network Solutions",
    icon: `${oldIconBasePath}networksolutions.png`,
  },
  [OrderSourceTypeCode.Magento]: {
    displayName: "Magento",
    typeCode: OrderSourceTypeCode.Magento,
    code: "MAGENTO",
    icon: `${newIconBasePath}magento.svg`,
    image: `${imageBasePath}magento.svg`,
    supportsImportInHub: true,
    supportsAddInHub: false,
  },
  [OrderSourceTypeCode.OrderDynamics]: {
    typeCode: OrderSourceTypeCode.OrderDynamics,
    code: "ORDERDYNAMICS",
    displayName: "OrderDynamics",
    icon: `${oldIconBasePath}orderdynamics.png`,
  },
  [OrderSourceTypeCode.SellerVantage]: {
    typeCode: OrderSourceTypeCode.SellerVantage,
    code: "AUCTIONSOUND",
    displayName: "SellerVantage",
    icon: `${oldIconBasePath}sellervantage.png`,
  },
  [OrderSourceTypeCode.WebShopManager]: {
    typeCode: OrderSourceTypeCode.WebShopManager,
    code: "WDSOLUTIONS",
    displayName: "Web Shop Manager",
    icon: `${oldIconBasePath}webshopmanager.png`,
  },
  [OrderSourceTypeCode.AmeriCommerce]: {
    typeCode: OrderSourceTypeCode.AmeriCommerce,
    code: "AMERICOMMERCE",
    displayName: "AmeriCommerce",
    icon: `${oldIconBasePath}americommerce.png`,
  },
  [OrderSourceTypeCode.CommerceInterface]: {
    typeCode: OrderSourceTypeCode.CommerceInterface,
    code: "COMMERCEINTERFACE",
    displayName: "CommerceInterface",
    icon: `${oldIconBasePath}commerceinterface.png`,
  },
  [OrderSourceTypeCode.SearchFit]: {
    typeCode: OrderSourceTypeCode.SearchFit,
    code: "SEARCHFIT",
    displayName: "SearchFit",
    icon: `${oldIconBasePath}searchfit.png`,
  },
  [OrderSourceTypeCode.GenericModule]: {
    typeCode: OrderSourceTypeCode.GenericModule,
    code: "GENERIC",
    displayName: "Generic - Module",
    icon: `${oldIconBasePath}genericmodule.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.ThreeDCart]: {
    typeCode: OrderSourceTypeCode.ThreeDCart,
    code: "3DCART",
    displayName: "3DCart",
    icon: `${oldIconBasePath}3dcart.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.BigCommerce]: {
    typeCode: OrderSourceTypeCode.BigCommerce,
    code: "BIGCOMMERCE",
    displayName: "BigCommerce",
    icon: `${oldIconBasePath}bigcommerce.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.GenericFile]: {
    typeCode: OrderSourceTypeCode.GenericFile,
    code: "GENERICFILE",
    displayName: "Generic - File",
    icon: `${oldIconBasePath}genericfile.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.Shopify]: {
    typeCode: OrderSourceTypeCode.Shopify,
    code: "SHOPIFY",
    displayName: "Shopify",
    icon: `${oldIconBasePath}shopify.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.Etsy]: {
    typeCode: OrderSourceTypeCode.Etsy,
    code: "ETSY",
    displayName: "Etsy",
    icon: `${oldIconBasePath}etsy.png`,
  },
  [OrderSourceTypeCode.NeweggMarketplace]: {
    typeCode: OrderSourceTypeCode.NeweggMarketplace,
    code: "NEWEGG",
    displayName: "Newegg",
    icon: `${oldIconBasePath}newegg.png`,
  },
  [OrderSourceTypeCode.BuyDotCom]: {
    typeCode: OrderSourceTypeCode.BuyDotCom,
    code: "BUYDOTCOM",
    displayName: "Buy.com",
    icon: `${oldIconBasePath}rakuten.png`,
  },
  [OrderSourceTypeCode.Sears]: {
    typeCode: OrderSourceTypeCode.Sears,
    code: "SEARS",
    displayName: "Sears",
    icon: `${oldIconBasePath}sears.png`,
  },
  [OrderSourceTypeCode.SolidCommerce]: {
    typeCode: OrderSourceTypeCode.SolidCommerce,
    code: "SOLIDCOMMERCE",
    displayName: "SolidCommerce",
    icon: `${oldIconBasePath}solidcommerce.png`,
  },
  [OrderSourceTypeCode.Brightpearl]: {
    typeCode: OrderSourceTypeCode.Brightpearl,
    code: "BRIGHTPEARL",
    displayName: "Brightpearl",
    icon: `${newIconBasePath}brightpearl.svg`,
    image: `${imageBasePath}brightpearl.svg`,
  },
  [OrderSourceTypeCode.BrightpearlHub]: {
    typeCode: OrderSourceTypeCode.BrightpearlHub,
    code: "BRIGHTPEARLHUB",
    displayName: "Brightpearl",
    icon: `${newIconBasePath}brightpearl.svg`,
    image: `${imageBasePath}brightpearl.svg`,
    supportsAddInHub: true,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.OrderDesk]: {
    typeCode: OrderSourceTypeCode.OrderDesk,
    code: "ORDERDESK",
    displayName: "OrderDesk",
    icon: `${oldIconBasePath}orderdesk.png`,
  },
  [OrderSourceTypeCode.Cart66Lite]: {
    typeCode: OrderSourceTypeCode.Cart66Lite,
    code: "CART66LITE",
    displayName: "Cart66 Lite",
    icon: `${oldIconBasePath}cart66.png`,
  },
  [OrderSourceTypeCode.Cart66Pro]: {
    typeCode: OrderSourceTypeCode.Cart66Pro,
    code: "CART66PRO",
    displayName: "Cart66 Pro",
    icon: `${oldIconBasePath}cart66.png`,
  },
  [OrderSourceTypeCode.Shopp]: {
    typeCode: OrderSourceTypeCode.Shopp,
    code: "SHOPP",
    displayName: "Shopp",
    icon: `${oldIconBasePath}shopp.png`,
  },
  [OrderSourceTypeCode.Shopperpress]: {
    typeCode: OrderSourceTypeCode.Shopperpress,
    code: "SHOPPERPRESS",
    displayName: "Shopperpress",
    icon: `${oldIconBasePath}shopperpress.png`,
  },
  [OrderSourceTypeCode.WPeCommerce]: {
    typeCode: OrderSourceTypeCode.WPeCommerce,
    code: "WPECOMMERCE",
    displayName: "WP eCommerce",
    icon: `${oldIconBasePath}wpecommerce.png`,
  },
  [OrderSourceTypeCode.Jigoshop]: {
    typeCode: OrderSourceTypeCode.Jigoshop,
    code: "JIGOSHOP",
    displayName: "Jigoshop",
    icon: `${oldIconBasePath}jigoshop.png`,
  },
  [OrderSourceTypeCode.WooCommerce]: {
    typeCode: OrderSourceTypeCode.WooCommerce,
    code: "WOOCOMMERCE",
    displayName: "WooCommerce",
    icon: `${oldIconBasePath}woocommerce.png`,
  },
  [OrderSourceTypeCode.ChannelSale]: {
    typeCode: OrderSourceTypeCode.ChannelSale,
    code: "CHANNELSALE",
    displayName: "ChannelSale",
    icon: `${oldIconBasePath}channelsale.png`,
  },
  [OrderSourceTypeCode.Fortune3]: {
    typeCode: OrderSourceTypeCode.Fortune3,
    code: "FORTUNE3",
    displayName: "Fortune3",
    icon: `${oldIconBasePath}fortune3.png`,
  },
  [OrderSourceTypeCode.LiveSite]: {
    typeCode: OrderSourceTypeCode.LiveSite,
    code: "LIVESITE",
    displayName: "LiveSite",
    icon: `${oldIconBasePath}livesite.png`,
  },
  [OrderSourceTypeCode.SureDone]: {
    typeCode: OrderSourceTypeCode.SureDone,
    code: "SUREDONE",
    displayName: "SureDone",
    icon: `${oldIconBasePath}suredone.png`,
  },
  [OrderSourceTypeCode.Zenventory]: {
    typeCode: OrderSourceTypeCode.Zenventory,
    code: "ZENVENTORY",
    displayName: "Zenventory",
    icon: `${oldIconBasePath}zenventory.png`,
  },
  [OrderSourceTypeCode.nopCommerce]: {
    typeCode: OrderSourceTypeCode.nopCommerce,
    code: "NOPCOMMERCE",
    displayName: "nopCommerce",
    icon: `${oldIconBasePath}nopcommerce.png`,
  },
  [OrderSourceTypeCode.LimeLightCRM]: {
    typeCode: OrderSourceTypeCode.LimeLightCRM,
    code: "LIMELIGHTCRM",
    displayName: "Lime Light CRM",
    icon: `${oldIconBasePath}limelightcrm.png`,
  },
  [OrderSourceTypeCode.OpenCart]: {
    typeCode: OrderSourceTypeCode.OpenCart,
    code: "OPENCART",
    displayName: "OpenCart",
    icon: `${oldIconBasePath}opencart.png`,
  },
  [OrderSourceTypeCode.SellerExpress]: {
    typeCode: OrderSourceTypeCode.SellerExpress,
    code: "SELLEREXPRESS",
    displayName: "SellerExpress",
    icon: `${oldIconBasePath}sellerexpress.png`,
  },
  [OrderSourceTypeCode.PowersportsSupport]: {
    typeCode: OrderSourceTypeCode.PowersportsSupport,
    code: "POWERSPORTSSUPPORT",
    displayName: "Powersport Support",
    icon: `${oldIconBasePath}powersportssupport.png`,
  },
  [OrderSourceTypeCode.CloudConversion]: {
    typeCode: OrderSourceTypeCode.CloudConversion,
    code: "CLOUDCONVERSION",
    displayName: "Cloud Conversion",
    icon: `${oldIconBasePath}cloudconversion.png`,
  },
  [OrderSourceTypeCode.CsCart]: {
    typeCode: OrderSourceTypeCode.CsCart,
    code: "CSCART",
    displayName: "CS-Cart",
    icon: `${oldIconBasePath}cscart.png`,
  },
  [OrderSourceTypeCode.PrestaShop]: {
    typeCode: OrderSourceTypeCode.PrestaShop,
    code: "PRESTA",
    displayName: "PrestaShop",
    icon: `${oldIconBasePath}prestashop.png`,
  },
  [OrderSourceTypeCode.LoadedCommerce]: {
    typeCode: OrderSourceTypeCode.LoadedCommerce,
    code: "LOADEDCOMMERCE",
    displayName: "Loaded Commerce",
    icon: `${oldIconBasePath}loadedcommerce.png`,
  },
  [OrderSourceTypeCode.Choxi]: {
    typeCode: OrderSourceTypeCode.Choxi,
    code: "NOMORERACK",
    displayName: "Choxi",
    icon: `${oldIconBasePath}choxi.png`,
  },
  [OrderSourceTypeCode.Groupon]: {
    typeCode: OrderSourceTypeCode.Groupon,
    code: "GROUPON",
    displayName: "Groupon",
    icon: `${oldIconBasePath}groupon.png`,
  },
  [OrderSourceTypeCode.StageBloc]: {
    typeCode: OrderSourceTypeCode.StageBloc,
    code: "STAGEBLOC",
    displayName: "StageBloc",
    icon: `${oldIconBasePath}stagebloc.png`,
  },
  [OrderSourceTypeCode.RevolutionParts]: {
    typeCode: OrderSourceTypeCode.RevolutionParts,
    code: "REVOLUTIONPARTS",
    displayName: "RevolutionParts",
    icon: `${oldIconBasePath}revolutionparts.png`,
  },
  [OrderSourceTypeCode.InstaStore]: {
    typeCode: OrderSourceTypeCode.InstaStore,
    code: "INSTASTORE",
    displayName: "InstanteStore",
    icon: `${oldIconBasePath}instantestore.png`,
  },
  [OrderSourceTypeCode.OrderBot]: {
    typeCode: OrderSourceTypeCode.OrderBot,
    code: "ORDERBOT",
    displayName: "OrderBot",
    icon: `${oldIconBasePath}orderbot.png`,
  },
  [OrderSourceTypeCode.OpenSky]: {
    typeCode: OrderSourceTypeCode.OpenSky,
    code: "OPENSKY",
    displayName: "OpenSky",
    icon: `${oldIconBasePath}opensky.png`,
  },
  [OrderSourceTypeCode.LemonStand]: {
    typeCode: OrderSourceTypeCode.LemonStand,
    code: "LEMONSTAND",
    displayName: "LemonStand",
    icon: `${oldIconBasePath}lemonstand.png`,
  },
  [OrderSourceTypeCode.SparkPay]: {
    typeCode: OrderSourceTypeCode.SparkPay,
    code: "SPARKPAY",
    displayName: "SparkPay",
    icon: `${oldIconBasePath}sparkpay.png`,
  },
  [OrderSourceTypeCode.Odbc]: {
    typeCode: OrderSourceTypeCode.Odbc,
    code: "ODBC",
    displayName: "ODBC",
    icon: `${oldIconBasePath}odbc.png`,
  },
  [OrderSourceTypeCode.Amosoft]: {
    typeCode: OrderSourceTypeCode.Amosoft,
    code: "AMOSOFT",
    displayName: "Amosoft",
    icon: `${oldIconBasePath}amosoft.png`,
  },
  [OrderSourceTypeCode.SellerCloud]: {
    typeCode: OrderSourceTypeCode.SellerCloud,
    code: "SELLERCLOUD",
    displayName: "SellerCloud",
    icon: `${oldIconBasePath}sellercloud.png`,
  },
  [OrderSourceTypeCode.InfiPlex]: {
    typeCode: OrderSourceTypeCode.InfiPlex,
    code: "INFIPLEX",
    displayName: "InfiPlex",
    icon: `${oldIconBasePath}infiplex.png`,
  },
  [OrderSourceTypeCode.Walmart]: {
    typeCode: OrderSourceTypeCode.Walmart,
    code: "WALMART",
    displayName: "Walmart",
    icon: `${oldIconBasePath}walmart.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.SellerActive]: {
    typeCode: OrderSourceTypeCode.SellerActive,
    code: "SELLERACTIVE",
    displayName: "SellerActive",
    icon: `${oldIconBasePath}selleractive.png`,
  },
  [OrderSourceTypeCode.GeekSeller]: {
    typeCode: OrderSourceTypeCode.GeekSeller,
    code: "GEEKSELLER",
    displayName: "GeekSeller",
    icon: `${oldIconBasePath}geekseller.png`,
  },
  [OrderSourceTypeCode.Jet]: {
    typeCode: OrderSourceTypeCode.Jet,
    code: "JET",
    displayName: "Jet",
    icon: `${oldIconBasePath}jet.png`,
  },
  [OrderSourceTypeCode.Manual]: {
    typeCode: OrderSourceTypeCode.Manual,
    code: "MANUAL",
    displayName: "Manual",
    icon: `${oldIconBasePath}manual.png`,
  },
  [OrderSourceTypeCode.Overstock]: {
    typeCode: OrderSourceTypeCode.Overstock,
    code: "OVERSTOCK",
    displayName: "Overstock",
    icon: `${oldIconBasePath}overstock.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.CommerceV3]: {
    typeCode: OrderSourceTypeCode.CommerceV3,
    code: "CV3",
    displayName: "CommerceV3",
    icon: `${oldIconBasePath}cv3.png`,
  },
  [OrderSourceTypeCode.Zentail]: {
    typeCode: OrderSourceTypeCode.Zentail,
    code: "ZENTAIL",
    displayName: "Zentail",
    icon: `${oldIconBasePath}zentail.png`,
  },
  [OrderSourceTypeCode.Bonanza]: {
    typeCode: OrderSourceTypeCode.Bonanza,
    code: "BONANZA",
    displayName: "Bonanza",
    icon: `${oldIconBasePath}bonanza.png`,
  },
  [OrderSourceTypeCode.Rakuten]: {
    typeCode: OrderSourceTypeCode.Rakuten,
    code: "RAKUTEN",
    displayName: "Rakuten",
    icon: `${oldIconBasePath}rakuten.png`,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.Api]: {
    typeCode: OrderSourceTypeCode.Api,
    code: "API",
    displayName: "API",
    icon: `${oldIconBasePath}/api.png`,
  },
  [OrderSourceTypeCode.WalmartHub]: {
    typeCode: OrderSourceTypeCode.WalmartHub,
    code: "WALMARTHUB",
    displayName: "Walmart",
    icon: `${oldIconBasePath}walmart.png`,
    image: `${imageBasePath}walmart.svg`,
    supportsAddInHub: true,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.ChannelAdvisorHub]: {
    typeCode: OrderSourceTypeCode.ChannelAdvisorHub,
    code: "CHANNELADVISORHUB",
    displayName: "ChannelAdvisor",
    icon: `${oldIconBasePath}channeladvisor.png`,
    image: `${imageBasePath}channelAdvisor.svg`,
    supportsAddInHub: false,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.VolusionHub]: {
    typeCode: OrderSourceTypeCode.VolusionHub,
    code: "VOLUSIONHUB",
    displayName: "Volusion",
    icon: `${oldIconBasePath}volusion.png`,
    image: `${imageBasePath}volusion.svg`,
    supportsAddInHub: true,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.GrouponHub]: {
    typeCode: OrderSourceTypeCode.GrouponHub,
    code: "GROUPONHUB",
    displayName: "Groupon",
    icon: `${oldIconBasePath}groupon.png`,
    image: `${imageBasePath}groupon.svg`,
    supportsAddInHub: true,
    supportsImportInHub: true,
  },
  [OrderSourceTypeCode.ShipWorksIntegrations]: {
    typeCode: OrderSourceTypeCode.ShipWorksIntegrations,
    code: "SHIPWORKSINTEGRATIONS",
    displayName: "Z Connect other order source types in the ShipWorks desktop application",
    icon: `${oldIconBasePath}shipworksintegrations.png`,
    image: `${imageBasePath}shipworksintegrations.svg`,
    supportsAddInHub: true,
  },
};

const listAll = (): IOrderSourceMetaData[] => {
  const orderSourceList = Object.values(orderSources);

  // don't want to ever display unknown in a list or something like that
  delete orderSourceList[OrderSourceTypeCode.Unknown];

  return orderSourceList;
};

const listAllThatSupportImportInHub = (): IOrderSourceMetaData[] =>
  listAll().filter((x) => x.supportsImportInHub);

const listAllThatSupportAddingInHub = (): IOrderSourceMetaData[] =>
  listAll().filter((x) => x.supportsAddInHub);

const getByTypeCode = (typeCode: OrderSourceTypeCode): IOrderSourceMetaData =>
  orderSources[typeCode] || orderSources[OrderSourceTypeCode.Unknown];

const getByCode = (codeName): IOrderSourceMetaData[] =>
  listAll().filter((x) => x.code.toLowerCase() === codeName);

export {
  listAll,
  listAllThatSupportImportInHub,
  listAllThatSupportAddingInHub,
  getByTypeCode,
  getByCode,
};
